import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@/context';
import { useUserAccounts } from '@/context/UserAccountsContext';
import { LoadingView } from '@/components';

export const AppLoader = ():JSX.Element => {
  const {loaded: authLoaded, authenticated} = useAuth();
  const {loaded: accountsLoaded, accounts, defaultAccountId} = useUserAccounts();
  const navigate = useNavigate();

  useEffect(() => {
    console.log("AppLoader: loaded", authLoaded, "authenticated", authenticated, "accountsLoaded", accountsLoaded, "accounts", accounts, "defaultAccountId", defaultAccountId);
    if (!authLoaded) return;

    if (authLoaded && !authenticated) {
      navigate('/login');
      return;
    }
  
    if (accountsLoaded) {
      if (accounts && accounts.length === 0) {
        navigate('/get-started');
      }
      else if (accounts && defaultAccountId) {
        navigate(`/account/${defaultAccountId}`);
      }
      else {
        navigate('/accounts');
      }
    }
  }, [authenticated, authLoaded, accountsLoaded, accounts, defaultAccountId, navigate]);

  
  return (
    <LoadingView/>
  )
}