import { UserAccountsApi } from '@/api';
import { createContext, useContext, useState, useEffect, PropsWithChildren } from 'react';
import { useAuth } from './AuthenticationContext';
import { UserAccountsResponse } from '@/types/FirebaseFunctionsResponses';
import { UserAccount } from '@/types';

type UserAccountsContextType = {
  loaded: boolean;

  accounts: UserAccount[] | undefined;
  selectedAccountId: string | undefined;
  defaultAccountId: string | undefined;
  addAccount: (accountId: string, nickName?: string) => void;
  updateAccountNickname: (accountId: string, nickName: string) => void;

  selectAccount: (accountId: string) => void;
  refreshAccounts: () => void;
};

const UserAccountsContext = createContext<UserAccountsContextType | undefined>(undefined);

export const UserAccountsProvider = ({ children }: PropsWithChildren): JSX.Element => {
  const [loaded, setLoaded] = useState(false);
  const [accounts, setAccounts] = useState<UserAccount[] | undefined>([]);
  const [selectedAccountId, setSelectedAccountId] = useState<string | undefined>(undefined);
  const [defaultAccountId, setDefaultAccountId] = useState<string | undefined>(undefined);
  const { authenticated } = useAuth();
  
  const loadAccounts =  async () => {
    const userAccountsResponse: UserAccountsResponse | undefined= await UserAccountsApi.getAccounts();

    setAccounts(userAccountsResponse ? userAccountsResponse.accounts : undefined);
    setDefaultAccountId(userAccountsResponse &&  userAccountsResponse.defaultAccount ? 
      userAccountsResponse.defaultAccount : undefined)
  }

  useEffect(() => {
    console.log('UserAccountsProvider useEffect with authenticated:', authenticated);
    if (!authenticated) {
      return;
    }
    
    const load = async () => { 
      await loadAccounts();
      setLoaded(true);
    }  
    
    load();

  }, [authenticated]);

  const addAccount = async (accountId: string, nickName?: string) => {
  }

  const updateAccountNickname = async (accountId: string, nickName: string) => {
  }

  const selectAccount = async (accountId: string) => {
    const account = accounts?.find(account => account.id === accountId);

    if (account) {
      setSelectedAccountId(accountId);
    }
  }

  const refreshAccounts = async () => {
    await loadAccounts();
  }

  return (
    <UserAccountsContext.Provider value={{ 
        loaded, accounts, selectedAccountId, selectAccount, defaultAccountId,
        addAccount, updateAccountNickname, refreshAccounts
     }}>
      {children}
    </UserAccountsContext.Provider>
  );
};

export const useUserAccounts = (): UserAccountsContextType => {
  const context = useContext(UserAccountsContext);
  if (!context) {
    throw new Error('useUserAccounts must be used within a UserAccountsProvider');
  }
  return context;
};
