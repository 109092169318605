import { createContext, useContext, useState, PropsWithChildren, useEffect, useCallback } from 'react';
import { AccountStatusResponse } from '@/types/FirebaseFunctionsResponses';
import { AccountStatusApiInstance } from '@/api'; // Assume this function exists to fetch account data
import { AccountData } from '@/types';
import { useUserAccounts } from './UserAccountsContext';

type AccountDataContextType = {
  loaded: boolean;

  refreshAccountData: () => void;

  accountData: AccountData | undefined;
  error: string | undefined;
};

const AccountDataContext = createContext<AccountDataContextType | undefined>(undefined);

export const AccountDataProvider = ({ children }: PropsWithChildren): JSX.Element => {
  const [accountData, setAccountData] = useState<AccountData | undefined>(undefined);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const {selectedAccountId} = useUserAccounts();
  
  const loadAccountData = useCallback(async (accountId: string) => {
    setLoaded(false);
    setError(undefined);

    try {
      const serverResponse = await AccountStatusApiInstance.getAccountStatus(accountId);

      if (!serverResponse) {
        setError('Account data not found');
        return;
      }

      updateAccountData(accountId, serverResponse);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred while fetching account data');
    } finally {
      setLoaded(true);
    }
  }, []);
  
  const refreshAccountData = useCallback(async () => {
    if (!selectedAccountId) return;

    await loadAccountData(selectedAccountId);
  }, [loadAccountData, selectedAccountId]);

  useEffect(() => {
    if (!selectedAccountId) return;
    
    if (selectedAccountId !== accountData?.accountId ||
        accountData.lastFetchedAt < new Date(Date.now() - 1000 * 60)
    ) {
      refreshAccountData();
    }
  }, [selectedAccountId, accountData, refreshAccountData]);

  const updateAccountData = async (accountId: string, data: AccountStatusResponse) => {
    let newAccountData: AccountData;
    
    newAccountData = {
      balance: data.balance,
      equity: data.equity,
      openPositions: data.openPositions,
      freeMargin: data.freeMargin,
      totalProfit: data.totalProfit,
      accountId: accountId,
      pairs: data.pairs,
      lastUpdatedAt: new Date(data.lastUpdatedAt),
      lastFetchedAt: new Date()
    };

    setAccountData(newAccountData);
  }

  return (
    <AccountDataContext.Provider value={{ 
      loaded, 

      accountData, 
      error, 

      refreshAccountData 
    }}>
      {children}
    </AccountDataContext.Provider>
  );
};

export const useAccountData = (): AccountDataContextType => {
  const context = useContext(AccountDataContext);
  if (!context) {
    throw new Error('useAccountData must be used within an AccountDataProvider');
  }
  return context;
};