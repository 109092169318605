import { useFunds } from "@/context/FundsContext";
import { AllTimeMetrics, UsdMetrics } from "./MainMetrics";

export const FundsSummary = (): JSX.Element => {
  const { fundsStats } = useFunds();
  
  console.log("Fund stas are ", fundsStats);

  // const unrealizedProfitPercentage = 1;//fundsStats.currentBalance > 0 ? (unrealizedProfit / balance) * 100 : 0;
  // const profitPercentage = equity > 0 ? (profit / equity) * 100 : 0;
  //const equityPercentage = balance > 0 ? (equity / balance ) * 100 : 0;
  const takenProfitPercentage = (fundsStats.allTimeProfit && fundsStats.totalWithdrawals) ? ((fundsStats.totalWithdrawals)/fundsStats.allTimeProfit * 100) : 0;
  const withdrawalsRatio = (fundsStats.totalDeposits && fundsStats.totalWithdrawals)? (fundsStats.totalWithdrawals / fundsStats.totalDeposits) * 100 : 0;

  return (
    <div className="grid gap-4 md:grid-cols-2 md:gap-8 lg:grid-cols-4">
      <AllTimeMetrics
        allTimeBalance={fundsStats.allTimeBalance} 
        currentBalance={fundsStats.currentBalance} 
        />
      <UsdMetrics title="All time Profit" 
        value={fundsStats.allTimeProfit? fundsStats.allTimeProfit : 0} 
        shouldShowProgress
        progress={takenProfitPercentage}
        progressTitle={takenProfitPercentage.toFixed(2) + '% withdrawn'} 
      />
      <UsdMetrics title="Total Deposits" 
        value={fundsStats.totalDeposits ? fundsStats.totalDeposits : 0} 
        shouldShowProgress={false}
        //progress={equityPercentage}
        //progressTitle={equityPercentage.toFixed(2) + '% from balance'} 
        />
      <UsdMetrics title="Total Withdrawals" 
        value={fundsStats.totalWithdrawals ? fundsStats.totalWithdrawals : 0} 
        shouldShowProgress={true}
        progress={withdrawalsRatio}
        progressTitle={withdrawalsRatio.toFixed(2) + '% from all time deposits'} 
        />
      {/*<UsdMetrics title="All time balance" 
        value={fundsStats.allTimeBalance} 
        //progress={equityPercentage}
        //progressTitle={equityPercentage.toFixed(2) + '% from balance'} 
        />
       <UsdMetrics title="Equity" 
        value={Math.abs(fundsStats.equity)} 
        progress={equityPercentage}
        progressTitle={equityPercentage.toFixed(2) + '% from balance'} 
        /> */}
      {/* <UsdMetrics title="Unrealized Profit" 
        value={fundsStats.unrealizedProfit} 
        progress={unrealizedProfitPercentage}
        progressTitle={unrealizedProfitPercentage.toFixed(2) + '% from balance'} 
      /> */}
    </div>
  );
};