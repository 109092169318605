import { FundsTransactionsRequestResponse } from "@/types/FirebaseFunctionsResponses";
import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions();

const TWO_WEEKS_IN_MS = 14 * 24 * 60 * 60 * 1000;

function loadCache(accountId: string) {
  const cachedData = localStorage.getItem(`cachedFundsTransactions_${accountId}`);
  const cachedTime = localStorage.getItem(`lastFetchTime_${accountId}`);
  return {
    cachedFundsTransactions: cachedData ? JSON.parse(cachedData) : null,
    lastFetchTime: cachedTime ? parseInt(cachedTime, 10) : 0,
  };
}

function saveCache(accountId: string, data: any) {
  localStorage.setItem(`cachedFundsTransactions_${accountId}`, JSON.stringify(data));
  localStorage.setItem(`lastFetchTime_${accountId}`, Date.now().toString());
}

export const FundsApi = {
  getFundsTransactions: async (accountId: string, ignoreCache: boolean = false): Promise<FundsTransactionsRequestResponse | undefined> => {
    try{
      const { cachedFundsTransactions, lastFetchTime } = loadCache(accountId);
      
      const currentTime = Date.now();
      if (!ignoreCache && cachedFundsTransactions && (currentTime - lastFetchTime) < TWO_WEEKS_IN_MS) {
          return cachedFundsTransactions;
      }
      const getFundsData = httpsCallable(functions, 'getFundsTransactions');
      const result = await getFundsData({accountId});

      console.log("FundsApi.getFundsTransactions response:", result);

      const response: FundsTransactionsRequestResponse = result.data as FundsTransactionsRequestResponse;

      console.log("FundsApi.getFundsTransactions response:", response);

      // Update the cache
      saveCache(accountId, response);

      return response;
    }
    catch(e){
      console.error("FundsApi.getFundsTransactions error:", e);
      return undefined;
    }
  },

  addTransaction: async (accountId: string, type: 'deposit' | 'withdrawal', amount: number, date: Date) => {
    try{
      const addFundsTransaction = httpsCallable(functions, 'addFundsTransaction');
      const result = await addFundsTransaction({accountId, type, amount, date});

      console.log("FundsApi.addTransaction response:", result);
      return result;
    }
    catch(e){
      console.error("FundsApi.addTransaction error:", e);
      return undefined;
    }
  }
}