import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '@/context';
import { LoadingView } from './Loaders';

const PrivateRoute = () => {
  const { loaded: authLoaded, authenticated, checkAuthStatus } = useAuth();
  const [isAuthChecked, setIsAuthChecked] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      await checkAuthStatus();
      setIsAuthChecked(true);
    };
    
    checkAuth();
  }, [checkAuthStatus]);

  if (!authLoaded || !isAuthChecked) {
    return <LoadingView />
  }
  
  if (!authenticated) {
    return <Navigate to="/login" replace />;
  }

  return <Outlet />;
};

export default PrivateRoute;