import { createContext, useContext, useState, PropsWithChildren, useEffect, useCallback } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { appFirebaseAuth } from './firebase/firebase';

type AuthenticationContextType = {
  loaded: boolean;

  authenticated: boolean;
  email: string | undefined;
  userId: string | undefined;

  setUser: (user: any | undefined) => void;
  loggedIn: () => void;
  loggedOut: () => void;

  checkAuthStatus: () => void;
};

const AuthenticationContext = createContext<AuthenticationContextType | undefined>(undefined);

export const AuthProvider = ({ children }: PropsWithChildren): JSX.Element => {
  const [loaded, setLoaded] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [userId, setUserId] = useState<string | undefined>();
  const [email, setEmail] = useState<string | undefined>();

  const setUser = useCallback((user: any | undefined) => {
    if (!user) {
      setUserId(undefined);
      setAuthenticated(false);
    }
    else {
      setEmail(user.email);
      setUserId(user.Id);
  
      setAuthenticated(true);
    }
  }, []);

  useEffect(() => {
    if (appFirebaseAuth.currentUser)
    {
      console.log('User is already logged in');
      setUser(appFirebaseAuth.currentUser);
      setAuthenticated(true);
    }

    const unsubscribe = onAuthStateChanged(appFirebaseAuth, (user) => {
      setUser(user);
      setLoaded(true);
    });

    setLoaded(true);

    return unsubscribe;
  },[setUser]);

  const loggedIn = async () => {
    setAuthenticated(true);
  }

  const loggedOut = async () => {
    setAuthenticated(false);
  }

  const checkAuthStatus = async () => {
    await appFirebaseAuth.authStateReady();

    if (appFirebaseAuth.currentUser)
    {
      console.log('User is already logged in');
      setUser(appFirebaseAuth.currentUser);
      setAuthenticated(true);
    }
    else {
      setUser(undefined);
      setAuthenticated(false);
    }
  }

  return (
    <AuthenticationContext.Provider value={{ loaded, authenticated, email, userId,
      setUser, loggedIn, loggedOut, checkAuthStatus }}>
      {children}
    </AuthenticationContext.Provider>
  );
};

export const useAuth = (): AuthenticationContextType => {
  const context = useContext(AuthenticationContext);
  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
};
