import { useParams } from 'react-router-dom';
import { FundsTable, FundsSummary } from './components';
import { useFunds } from '@/context/FundsContext';
import { useEffect } from 'react';

export const FundsPage = (): JSX.Element => {
  const { accountId } = useParams();
  const { transactions, refresh } = useFunds();

  useEffect(() => {
    if (!accountId) return;
    refresh(accountId);
  },[accountId, refresh]);

  return (
    <div className="flex flex-col gap-8">
      <FundsSummary 
      />
      <FundsTable
        transactions={transactions}
      />
    </div>
  );
};